import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RightChevronTertiary from '../img/RightChevronTertiary.svg';
import Swift from '../img/SwiftLogo.svg';
import Triangle from '../img/triangle.svg';

const Maintenance = () => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const { t } = useTranslation();
	document.title = 'IFTS De Amicis';

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	return (
		<div id="homepage">
			<section id="homepage-hero">
				<div className="hero" style={{ height: windowHeight }}>
					<img className="logo shadow" src={Swift} alt="Logo di Swift" />
					<h6>{t('homepage.hero.edition')}</h6>
					<h1>{t('homepage.hero.title')}</h1>
					<h5>{t('homepage.hero.text')}</h5>
					<div className="maintenance-border">
						<div className="maintenance-alert">
							<img src={Triangle} alt="attention mark" />
							<p>{t('maintenance.text')}</p>
						</div>
						<a
							href="mailto:ifts@ideamicis.it"
							className="simple-button tertiary-background"
						>
							<img src={RightChevronTertiary} alt="Right chevron" />
							{t('maintenance.button')}
						</a>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Maintenance;
