import { useTranslation } from 'react-i18next';
import '../components/css/utilities.css';
import '../components/css/style.css';
import '../components/css/tablet.css';
import '../components/css/mobile.css';
import '../components/css/smallMobile.css';
import '../components/css/shortMobile.css';
import Overlay from '../components/Overlay';
import illustration from '../img/Swift.svg';
import SecondaryHero from '../components/SecondaryHero';
import Description from '../components/Description';
import programData from '../components/json/program.json';
import exampleCalendar from '../img/monthlyCalendarExample.png';

const Programma = () => {
	const { t } = useTranslation();
	document.title = `${t('navbar.program')}  - IFTS De Amicis`;
	return (
		<div id="programma">
			<section id="programma-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Logo di Swift"
					title={t('programma.hero.title')}
					text1={t('programma.hero.text1')}
					text2={t('programma.hero.text2')}
					text3={t('programma.hero.text3')}
				>
					<div className="time">
						<div>
							<h6>{t('programma.hero.time.class')}</h6>
							<p>500 h</p>
						</div>
						<div>
							<h6>{t('programma.hero.time.company')}</h6>
							<p>500 h</p>
						</div>
						<div>
							<h6>{t('programma.hero.time.home')}</h6>
							<p>&infin; h</p>
						</div>
					</div>
				</SecondaryHero>
				<Overlay
					label={t('programma.hero.chevron')}
					destination="#subject-description"
				/>
			</section>
			<section id="subject-description">
				<Description data={programData.program} hours></Description>
			</section>

			{/* <SecondaryHero>
			<div>
				<h5>{t('programma.example')}</h5>
				<img src={exampleCalendar} alt='Esempio calendario mensile' style={{height:400, width:window.width/1.5}}  />
				</div>
				</SecondaryHero> */}
					</div>
	);
};
export default Programma;
