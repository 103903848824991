import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from '../components/Overlay';
import SecondaryHero from '../components/SecondaryHero';
import illustration from '../img/Asterisco.svg';
import Grid from '../components/Grid';
import { db } from '../FirebaseConfig';
import { getDocs, collection, query, orderBy } from 'firebase/firestore';

const Team = () => {
	const [teamData, setTeamData] = useState([]);
	const { t } = useTranslation();
	document.title = `${t('navbar.team')}  - IFTS De Amicis`;

	const fetchPost = async () => {
		await getDocs(query(collection(db, 'Team'), orderBy('order'))).then(
			(querySnapshot) => {
				const newData = querySnapshot.docs.map((doc) => ({
					...doc.data(),
				}));
				setTeamData(newData);
			}
		);
	};

	useEffect(() => {
		fetchPost();
	}, []);
	return (
		<div id="team">
			<section id="team-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Logo Asterisco DeAmicis"
					title={t('team.hero.title')}
					text1={t('team.hero.text1')}
					text2={t('team.hero.text2')}
				></SecondaryHero>
				<Overlay
					label={t('team.hero.chevron')}
					destination="#team-description"
				/>
			</section>
			{/* Team */}
			<section id="team-description">
				<Grid
					background="primary-background"
					gridsize="four"
					title={t('team.hero.chevron')}
					data={teamData}
				></Grid>
			</section>
		</div>
	);
};
export default Team;
