import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import illustration from '../img/Privacy.svg';
import Overlay from '../components/Overlay';

const PrivacyPolicy = (props) => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const { t } = useTranslation();
	document.title = `${t('privacypolicy.hero.title')}  - IFTS De Amicis`;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	return (
		<div id="aacte">
			{/* Hero */}
			<section id="privacy-hero">
				<div className="secondary-hero" style={{ height: windowHeight }}>
					<div className="header center">
						<img
							className="illustration"
							src={illustration}
							alt="Privacy Icon"
						/>
						<div>
							<h1>{t('privacypolicy.hero.title')}</h1>
						</div>
					</div>
				</div>
				<Overlay
					label={t('privacypolicy.hero.chevron')}
					destination="#privacy-description"
				/>
			</section>
			{/* Description */}
			<section id="privacy-description">
				<div className="privacy-description layout">
					<h5>{t('privacypolicy.description1.title')}</h5>
					<p>
						{t('privacypolicy.description1.text')}{' '}
						<a
							href="https://www.iftsdeamicis.com"
							target="_blank"
							rel="noreferrer"
						>
							https://www.iftsdeamicis.com
						</a>
					</p>
					<h5>{t('privacypolicy.description2.title')}</h5>
					<p>{t('privacypolicy.description2.text')}</p>
					<h5>{t('privacypolicy.description3.title')}</h5>
					<p>{t('privacypolicy.description3.text')}</p>
					<h5>{t('privacypolicy.description4.title')}</h5>
					<p>{t('privacypolicy.description4.text')}</p>
					<h5>{t('privacypolicy.description5.title')}</h5>
					<p>{t('privacypolicy.description5.text')}</p>
				</div>
			</section>
		</div>
	);
};
export default PrivacyPolicy;
