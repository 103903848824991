import { useState, useEffect } from 'react';
import { db } from '../src/FirebaseConfig';
import { getDocs, collection, where, query } from 'firebase/firestore';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AppNavigator from './components/AppNavigator';
import Homepage from './containers/Homepage';
import Programma from './containers/Programma';
import Applicazioni from './containers/Applicazioni';
import Team from './containers/Team';
import Iscriviti from './containers/Iscriviti';
import Collabora from './containers/Collabora';
import Footer from './components/Footer';
import AATCE from './containers/AATCE';
import PrivacyPolicy from './containers/PrivacyPolicy';
import NotFound from './containers/NotFound';
import Success from './containers/Success';
import Maintenance from './containers/Maintenance';

export default function App() {
	const [maintenance, setMaintenance] = useState();

	const fetchPost = async () => {
		await getDocs(
			query(collection(db, 'Settings'), where('key', '==', 'maintenance'))
		).then((querySnapshot) => {
			const newData = querySnapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setMaintenance(newData[0].value);
		});
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return maintenance ? (
		<Maintenance />
	) : (
		<Router>
			<AppNavigator />
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/programma" element={<Programma />} />
				<Route path="/applicazioni" element={<Applicazioni />} />
				<Route path="/team" element={<Team />} />
				<Route path="/iscriviti" element={<Iscriviti />} />
				<Route path="/collabora" element={<Collabora />} />
				<Route path="/AATCE" element={<AATCE />} />
				<Route path="/privacypolicy" element={<PrivacyPolicy />} />
				<Route path="/success" element={<Success />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</Router>
	);
}
