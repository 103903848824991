import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyC4-78KRKBx7WRqbviUF89MJzTkUe6bJwE',
	authDomain: 'ifts-deamicis-site.firebaseapp.com',
	projectId: 'ifts-deamicis-site',
	storageBucket: 'ifts-deamicis-site.appspot.com',
	messagingSenderId: '492839200757',
	appId: '1:492839200757:web:99be071fc12e544ba622bc',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
