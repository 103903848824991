import { useTranslation } from 'react-i18next';
import '../components/css/utilities.css';
import '../components/css/style.css';
import '../components/css/tablet.css';
import '../components/css/mobile.css';
import '../components/css/smallMobile.css';
import '../components/css/shortMobile.css';
import illustration from '../img/exclamationmark.svg';
import RightChevronTertiary from '../img/RightChevronTertiary.svg';
import SecondaryHero from '../components/SecondaryHero';

const NotFound = () => {
	const { t } = useTranslation();
	document.title = `${t('notfound.title')}  - IFTS De Amicis`;
	return (
		<div id="error">
			<section id="error-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Exclamationmark Icon"
					title={t('notfound.title')}
					text1={t('notfound.text')}
				>
					<a href="/" className="simple-button tertiary-background">
						<img src={RightChevronTertiary} alt="Right chevron" />
						{t('notfound.button')}
					</a>
				</SecondaryHero>
			</section>
		</div>
	);
};
export default NotFound;
