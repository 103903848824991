import { useState, useEffect } from 'react';
import { db } from '../FirebaseConfig';
import { getDocs, collection, where, query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import Overlay from '../components/Overlay';
import SecondaryHero from '../components/SecondaryHero';
import illustration from '../img/Badge.svg';

const Iscriviti = () => {
	const [subscribe, setSubscribe] = useState();
	const { t } = useTranslation();
	document.title = `${t('navbar.subscribe')}  - IFTS De Amicis`;

	const fetchPost = async () => {
		await getDocs(
			query(collection(db, 'Settings'), where('key', '==', 'subscribe'))
		).then((querySnapshot) => {
			const newData = querySnapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setSubscribe(newData[0].value);
		});
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return subscribe ? (
		<div id="iscriviti">
			<section id="iscriviti-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Badge DeAmicis"
					title={t('iscriviti.hero.title')}
					text1={t('iscriviti.hero.text1')}
					text2={t('iscriviti.hero.text2')}
				></SecondaryHero>
				<Overlay label={t('iscriviti.hero.chevron')} destination="#form" />
			</section>
			{/* Form */}
			<section id="form">
				<div className="form secondary-background">
					<div className="layout">
						<form
							id="subscription-form"
							action="subscriptionForm.php"
							method="POST"
							className="iscrizione-form"
						>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="name">{t('form.name')}*</label>
									<input type="text" name="name" id="name" required />
								</div>

								<div className="form-control half-form">
									<label htmlFor="surname">{t('form.surname')}*</label>
									<input type="text" name="surname" id="surname" required />
								</div>
							</div>
							<div className="form-control">
								<label htmlFor="birthdate">{t('form.birthdate')}</label>
								<input type="date" name="birthdate" id="birthdate" />
							</div>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="address">{t('form.address')}*</label>
									<input type="text" name="address" id="address" required />
								</div>
								<div className="form-control half-form">
									<label htmlFor="city">{t('form.city')}*</label>
									<input type="text" name="city" id="city" required />
								</div>
							</div>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="province">{t('form.province')}*</label>
									<select id="province" name="province" required>
										<option value="BG">Bergamo (BG)</option>
										<option value="BS">Brescia (BS)</option>
										<option value="CO">Como (CO)</option>
										<option value="CR">Cremona (CR)</option>
										<option value="LC">Lecco (LC)</option>
										<option value="LO">Lodi (LO)</option>
										<option value="MN">Mantova (MN)</option>
										<option value="MI">Milano (MI)</option>
										<option value="MB">Monza e Brianza (MB)</option>
										<option value="PV">Pavia (PV)</option>
										<option value="SO">Sondrio (SO)</option>
										<option value="VA">Varese (VA)</option>
									</select>
								</div>
								<div className="form-control half-form">
									<label htmlFor="cap">{t('form.cap')}*</label>
									<input type="text" name="cap" id="cap" required />
								</div>
							</div>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="phone">{t('form.phone')}*</label>
									<input type="tel" name="phone" id="phone" required />
								</div>
								<div className="form-control half-form">
									<label htmlFor="email">{t('form.email')}*</label>
									<input type="email" name="email" id="email" required />
								</div>
							</div>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="qualification">
										{t('form.qualification')}*
									</label>
									<select name="qualification" id="qualification" required>
										<option value="" defaultValue disabled hidden></option>
										<option value="diplomaIstitutoTecnico">
											{t('form.qualification.technical')}
										</option>
										<option value="diplomaSuperioreClassico">
											{t('form.qualification.classical')}
										</option>
										<option value="diplomaSuperioreScientifico">
											{t('form.qualification.scientific')}
										</option>
										<option value="diplomaSuperioreAltro">
											{t('form.qualification.highschool')}
										</option>

										<option value="laureTriennale">
											{t('form.qualification.bachelorsdegree')}
										</option>
										<option value="laureaMagistrale">
											{t('form.qualification.mastersdegree')}
										</option>
										<option value="altro">
											{t('form.qualification.other')}
										</option>
									</select>
								</div>
								<div className="form-control half-form">
									<label htmlFor="knowAboutUs">{t('form.knowAboutUs')}*</label>
									<select id="knowAboutUs" name="knowAboutUs" required>
										<option value="" defaultValue disabled hidden></option>
										<option value="google">Google</option>
										<option value="facebook">Facebook</option>
										<option value="linkedin">Linkedin</option>
										<option value="instagram">Instagram</option>
										<option value="passaparola">
											{t('form.knowAboutUs.wordofmouth')}
										</option>
									</select>
								</div>
							</div>

							<div className="checkbox-control">
								<input
									type="checkbox"
									id="privacy"
									name="privacy"
									value="true"
									required
								/>
								<label htmlFor="privacy">{t('form.privacy')}*</label>
							</div>
							<div className="submit-control">
								<input
									type="submit"
									value={t('form.button')}
									id="submit"
									className="button-w"
								/>
							</div>
						</form>
					</div>
				</div>
			</section>
		</div>
	) : (
		<div id="iscriviti">
			<section id="iscriviti-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Badge DeAmicis"
					title={t('iscriviti.hero.title')}
					text1={t('iscriviti.close.hero.text1')}
				></SecondaryHero>
			</section>
		</div>
	);
};
export default Iscriviti;
