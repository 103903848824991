import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from '../components/Overlay';
import RightChevronTertiary from '../img/RightChevronTertiary.svg';
import RightChevronSecondary from '../img/RightChevronSecondary.svg';
import Swift from '../img/SwiftLogo.svg';
import subjectData from '../components/json/subject.json';
import WWDC from '../img/WWDC.png';
import Grid from '../components/Grid';
import { db } from '../FirebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

const Homepage = () => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [partnershipData, setPartnershipData] = useState([]);
	const { t } = useTranslation();
	document.title = `${t('navbar.homepage')}  - IFTS De Amicis`;

	const fetchPost = async () => {
		await getDocs(collection(db, 'Partnership')).then((querySnapshot) => {
			const newData = querySnapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setPartnershipData(newData);
		});
	};

	useEffect(() => {
		fetchPost();
		const handleWindowResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<div id="homepage">
		<Helmet>
{ /* Standard metadata tags */ }
<title>Corso iOS App Developer IFTS</title>
<meta name='description' content="Diventa uno sviluppatore di applicazioni per il mondo Apple con il corso gratuito IFTS degli Istituti De Amicis." />
<meta
        name="keywords"
        content="Corso, IFTS, Applicazioni, App, iOS, DeAmicis, Milano, De Amicis, macbook, gratuito, developer, swift, programmatore, informatica, programmazione, apple, corso ifts lombarda, de amicis milano, ifts milano, istituti de amicis milano, ifts lombardia, apple education, certificazione apple, swift corso, scuole, apple certified training center, apple certified trainer, back-end, ui/ux"
      />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content="website" />
<meta property="og:title" content="Corso iOS App Developer IFTS" />
<meta property="og:description" content="Diventa uno sviluppatore di applicazioni per il mondo Apple con il corso gratuito IFTS degli Istituti De Amicis." />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content="Scar Group Development" />
<meta name="twitter:card" content="website" />
<meta name="twitter:title" content="Corso iOS App Developer IFTS" />
<meta name="twitter:description" content="Diventa uno sviluppatore di applicazioni per il mondo Apple con il corso gratuito IFTS degli Istituti De Amicis." />
{ /* End Twitter tags */ }
</Helmet>
			<section id="homepage-hero">
				<div className="hero" style={{ height: windowHeight }}>
					
					<img className="logo shadow" src={Swift} alt="Logo di Swift" />
					<h6>{t('homepage.hero.edition')}</h6>
					<h1>{t('homepage.hero.title')}</h1>
					<h5>{t('homepage.hero.text')}</h5>
					<a href="/iscriviti" className="button">
						{t('homepage.hero.button')}
					</a>
				</div>
				<Overlay label={t('homepage.hero.chevron')} destination="#questions" />
			</section>
			{/* Questions  */}
			<section id="questions">
				<div className="questions secondary-background">
					<div className="layout">
						<div className="question left">
							<h4>{t('homepage.questions1.title')}</h4>
							<p>{t('homepage.questions1.text1')}</p>
							<p>{t('homepage.questions1.text2')}</p>
						</div>
						<div className="question right">
							<h4>{t('homepage.questions2.title')}</h4>
							<p>{t('homepage.questions2.text1')}</p>
							<p>{t('homepage.questions2.text2')}</p>
							<p>{t('homepage.questions2.text3')}</p>
						</div>
						<div className="question left">
							<h4>{t('homepage.questions3.title')}</h4>
							<p>{t('homepage.questions3.text1')}</p>
							<p>{t('homepage.questions3.text2')}</p>

							<iframe
								title="map"
								className="map"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23665.736956046316!2d9.183347475460161!3d45.45683385361622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c41e2b30fe81%3A0x7069da2eb0307df5!2sIstituti%20E.%20De%20Amicis%20S.r.l.!5e0!3m2!1sit!2sit!4v1679569108198!5m2!1sit!2sit"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</div>
				</div>
			</section>
			{/* Subject */}
			<section id="subject">
				<Grid
					background="primary-background"
					gridsize="three"
					title={t('homepage.subject.title')}
					data={subjectData.subject}
					shadow
					linkDestination="/programma"
					buttonBackground="tertiary-background"
					buttonIcon={RightChevronTertiary}
					buttonLabel={t('homepage.subject.button')}
				/>
			</section>
			{/* Ispiration */}
			<section id="ispiration">
				<div className="ispiration secondary-background">
					<div className="layout">
						<img className="image" src={WWDC} alt="WWDC" />
						<h4>{t('homepage.ispiration.title')}</h4>
						<p>{t('homepage.ispiration.text1')}</p>
						<p>{t('homepage.ispiration.text2')}</p>
						<p>{t('homepage.ispiration.text3')}</p>
						<a
							href="/applicazioni"
							className="simple-button secondary-background"
						>
							<img src={RightChevronSecondary} alt="Right chevron" />
							{t('homepage.ispiration.button')}
						</a>
					</div>
				</div>
			</section>
			{/* Partnership */}
			<section id="partnership">
				<Grid
					background="primary-background"
					gridsize="three"
					title={t('homepage.partnership.title')}
					data={partnershipData}
					linkDestination="/collabora"
					buttonBackground="tertiary-background"
					buttonIcon={RightChevronTertiary}
					buttonLabel={t('homepage.partnership.button')}
				/>
			</section>
		</div>
	);
};
export default Homepage;
