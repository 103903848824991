import { useTranslation } from 'react-i18next';
import '../components/css/utilities.css';
import '../components/css/style.css';
import '../components/css/tablet.css';
import '../components/css/mobile.css';
import '../components/css/smallMobile.css';
import '../components/css/shortMobile.css';
import illustration from '../img/checkmark.svg';
import RightChevronTertiary from '../img/RightChevronTertiary.svg';
import SecondaryHero from '../components/SecondaryHero';

const Success = () => {
	const { t } = useTranslation();
	document.title = `${t('success.title')}  - IFTS De Amicis`;

	return (
		<div id="error">
			<section id="error-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Checkmark Icon"
					title={t('success.title')}
					text1={t('success.text')}
				>
					<a href="/" className="simple-button tertiary-background">
						<img src={RightChevronTertiary} alt="Right chevron" />
						{t('success.button')}
					</a>
				</SecondaryHero>
			</section>
		</div>
	);
};
export default Success;
