import { useTranslation } from 'react-i18next';
import illustration from '../img/Puzzle.svg';
import Overlay from '../components/Overlay';
import SecondaryHero from '../components/SecondaryHero';

const Collabora = () => {
	const { t } = useTranslation();
	document.title = `${t('navbar.workwithus')}  - IFTS De Amicis`;
	return (
		<div id="collabora">
			<section id="collabora-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Immagine Puzzle"
					title={t('collabora.hero.title')}
					text1={t('collabora.hero.text1')}
					text2={t('collabora.hero.text2')}
					text3={t('collabora.hero.text3')}
				></SecondaryHero>
				<Overlay label={t('collabora.hero.chevron')} destination="#form" />
			</section>
			{/* Form */}
			<section id="form">
				<div className="form secondary-background">
					<div className="layout">
						<form
							id="partnership-form"
							action="partnershipForm.php"
							method="POST"
							className="collabora-form"
						>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="name">{t('form.name')}*</label>
									<input type="text" name="name" id="name" required />
								</div>

								<div className="form-control half-form">
									<label htmlFor="surname">{t('form.surname')}*</label>
									<input type="text" name="surname" id="surname" required />
								</div>
							</div>
							<div className="row-form">
								<div className="form-control half-form">
									<label htmlFor="email">{t('form.email')}*</label>
									<input type="email" name="email" id="email" required />
								</div>
								<div className="form-control half-form">
									<label htmlFor="whoAreYou">{t('form.whoAreYou')}*</label>
									<select id="whoAreYou" name="whoAreYou" required>
										<option value="azienda">
											{t('form.whoAreYou.company')}
										</option>
										<option value="privato">
											{t('form.whoAreYou.professional')}
										</option>
									</select>
								</div>
							</div>
							<div className="form-control">
								<label htmlFor="message">{t('form.message')}*</label>
								<textarea
									name="message"
									id="message"
									cols="30"
									rows="10"
									required
								></textarea>
							</div>
							<div className="checkbox-control">
								<input
									type="checkbox"
									id="privacy"
									name="privacy"
									value="true"
									required
								/>
								<label htmlFor="privacy">{t('form.privacy')}*</label>
							</div>

							<div className="submit-control">
								<input
									type="submit"
									value={t('form.button')}
									id="submit"
									className="button-w"
								/>
							</div>
						</form>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Collabora;
