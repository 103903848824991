import { useTranslation } from 'react-i18next';
import SecondaryHero from '../components/SecondaryHero';
import illustration from '../img/Apple.svg';
import AACTEbadge from '../img/AATCE.svg';

const AATCE = (props) => {
	const { t } = useTranslation();
	document.title = 'Apple Authorized Training Center - IFTS De Amicis';
	return (
		<div id="aacte">
			<section id="aacte-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="apple logo"
					title={t('AACTE.hero.title')}
					text1={t('AACTE.hero.text1')}
					text2={t('AACTE.hero.text2')}
					text3={t('AACTE.hero.text3')}
				></SecondaryHero>
			</section>
			{/* Description */}
			<section id="aacte-description">
				<div className="aacte-description layout">
					<div className="aacte-header">
						<img src={AACTEbadge} alt="Logo AATCE" />
						<p>{t('AACTE.description.text1')}</p>
					</div>
					<p>{t('AACTE.description.text2')}</p>
					<p>{t('AACTE.description.text3')}</p>
				</div>
			</section>
		</div>
	);
};
export default AATCE;
