import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../components/css/utilities.css';
import '../components/css/style.css';
import '../components/css/tablet.css';
import '../components/css/mobile.css';
import '../components/css/smallMobile.css';
import '../components/css/shortMobile.css';
import illustration from '../img/xcode.png';
import Overlay from '../components/Overlay';
import SecondaryHero from '../components/SecondaryHero';
import Description from '../components/Description';
import { db } from '../FirebaseConfig';
import { getDocs, collection, query, orderBy } from 'firebase/firestore';

const Applicazioni = () => {
	const [appsData, setAppsData] = useState([]);
	const [reviewsData, setReviewsData] = useState([]);
	const { t } = useTranslation();
	document.title = `${t('navbar.application')}  - IFTS De Amicis`;

	const fetchPost = async () => {
		await getDocs(query(collection(db, 'Apps'), orderBy('order'))).then(
			(querySnapshot) => {
				const newDataApps = querySnapshot.docs.map((doc) => ({
					...doc.data(),
				}));
				setAppsData(newDataApps);
			}
		);
		await getDocs(collection(db, 'Reviews')).then((querySnapshot) => {
			const newDataReviews = querySnapshot.docs.map((doc) => ({
				...doc.data(),
			}));
			setReviewsData(newDataReviews);
		});
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return (
		<div id="applicazioni">
			<section id="applicazioni-hero">
				<SecondaryHero
					image={illustration}
					imgDescription="Xcode Icon"
					title={t('applicazioni.hero.title')}
					text1={t('applicazioni.hero.text1')}
					text2={t('applicazioni.hero.text2')}
					link="https://apps.apple.com/it/developer/istituti-edmondo-de-amicis-srl/id1219261025"
					linkText="Istituti E. De Amicis - Milano."
					text3={t('applicazioni.hero.text3')}
				></SecondaryHero>
				<Overlay
					label={t('applicazioni.hero.chevron')}
					destination="#app-description"
				/>
			</section>
			{/* App */}
			<section id="app-description">
				<Description data={appsData}></Description>
			</section>
			{/* Reviews*/}
			<section id="reviews">
				<div className="secondary-background">
					<Description data={reviewsData}></Description>
				</div>
			</section>
		</div>
	);
};
export default Applicazioni;
