import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';
import { ReactComponent as SwiftLogo } from '../img/SwiftLogo.svg';
import { ReactComponent as HamburgerMenuIcon } from '../img/HamburgerMenu.svg';
import { ReactComponent as CloseIcon } from '../img/Close.svg';

const AppNavigator = () => {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const location = useLocation();

	function openMenu(status) {
		if (status) {
			document.getElementById('mobile-menu').style.width = '100%';
		} else {
			document.getElementById('mobile-menu').style.width = '0%';
		}
	}

	const changeLanguage = () => {
		if (
			localStorage.getItem('language') === 'it' ||
			!localStorage.getItem('language')
		) {
			localStorage.setItem('language', 'en');
			i18n.changeLanguage('en');
		} else {
			localStorage.setItem('language', 'it');
			i18n.changeLanguage('it');
		}
	};

	return (
		<header className="header">
			<div id="mobile-menu" className="mobile-menu">
				<button
					className="closebtn"
					onClick={() => {
						openMenu(false);
					}}
				>
					<CloseIcon />
				</button>
				<div className="menu-content">
					<a href="/">{t('navbar.homepage')}</a>
					<a href="/programma">{t('navbar.program')}</a>
					<a href="/applicazioni">{t('navbar.application')}</a>
					<a href="/team">{t('navbar.team')}</a>
					<a href="/iscriviti">{t('navbar.subscribe')}</a>
					<a href="/collabora">{t('navbar.workwithus')}</a>
					<a
						href="https://deamicismilano.com/"
						target="_blank"
						rel="noreferrer"
					>
						{t('navbar.deamicis')}
					</a>
					<button
						onClick={() => {
							i18n.language === 'it'
								? i18n.changeLanguage('en')
								: i18n.changeLanguage('it');
						}}
						className="language"
					>
						{i18n.language === 'it' ? '🇬🇧 ' : '🇮🇹 '}
						{t('changelanguage')}
					</button>
				</div>
			</div>
			<div id="navbar" className="navbar">
				<div className="content">
					<a href="/">
						<SwiftLogo className="logo" alt="Logo di Swift" />
					</a>
					<nav>
						<ul>
							<li>
								<a
									className={location.pathname === '/' ? 'selected' : ''}
									href="/"
								>
									{t('navbar.homepage')}
								</a>
							</li>
							<li>
								<a
									className={
										location.pathname === '/programma' ? 'selected' : ''
									}
									href="/programma"
								>
									{t('navbar.program')}
								</a>
							</li>
							<li>
								<a
									className={
										location.pathname === '/applicazioni' ? 'selected' : ''
									}
									href="/applicazioni"
								>
									{t('navbar.application')}
								</a>
							</li>
							<li>
								<a
									className={location.pathname === '/team' ? 'selected' : ''}
									href="/team"
								>
									{t('navbar.team')}
								</a>
							</li>
							<li>
								<a
									className={
										location.pathname === '/iscriviti' ? 'selected' : ''
									}
									href="/iscriviti"
								>
									{t('navbar.subscribe')}
								</a>
							</li>
							<li>
								<a
									className={
										location.pathname === '/collabora' ? 'selected' : ''
									}
									href="/collabora"
								>
									{t('navbar.workwithus')}
								</a>
							</li>
							<li>
								<a
									href="https://deamicismilano.com/"
									target="_blank"
									rel="noreferrer"
								>
									{t('navbar.deamicis')}
								</a>
							</li>
							<li>
								<button onClick={changeLanguage} className="tooltip">
									{i18n.language === 'it' ? '🇬🇧' : '🇮🇹'}
									<span className="tooltiptext tooltip-bottom">
										{t('changelanguage')}
									</span>
								</button>
							</li>

							<li
								className="icon-mobile-menu"
								onClick={() => {
									openMenu(true);
								}}
							>
								<HamburgerMenuIcon />
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default AppNavigator;
