import { useTranslation } from 'react-i18next';
import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';

const Grid = (props) => {
	const { i18n } = useTranslation();

	return props.data ? (
		<div className={`layout grid-wrapper ${props.background}`}>
			<h4>{props.title}</h4>
			<div className="row">
				{props.data.map((item) => (
					<div className={`item ${props.gridsize}`} key={item.id}>
						{!!item.link ? (
							<a href={item.link} target="_blank" rel="noreferrer">
								<img
									src={item.img}
									alt={item.description}
									className={props.shadow ? 'shadow' : null}
								/>
							</a>
						) : (
							<img
								src={item.img}
								alt={item.description}
								className={props.shadow ? 'shadow' : null}
							/>
						)}

						{!!item.titleit && !!item.titleen ? (
							<h5>{i18n.language === 'en' ? item.titleen : item.titleit}</h5>
						) : null}
						{!!item.captionit && !!item.captionen ? (
							<p>{i18n.language === 'en' ? item.captionen : item.captionit}</p>
						) : null}
					</div>
				))}
			</div>
			{props.buttonLabel ? (
				<a
					href={props.linkDestination}
					className={`simple-button ${props.buttonBackground}`}
				>
					<img src={props.buttonIcon} alt="Chevron" />
					{props.buttonLabel}
				</a>
			) : null}
		</div>
	) : (
		<></>
	);
};
export default Grid;
