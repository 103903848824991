import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';
import Chevron from '../img/Chevron.svg';

const Overlay = (props) => {
	return (
		<div className="overlay">
			<a className="chevron jump" href={props.destination}>
				<p>{props.label}</p>
				<img src={Chevron} alt="chevron" />
			</a>
		</div>
	);
};
export default Overlay;
