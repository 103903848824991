import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	it: {
		translation: translationIT,
	},
};
i18next.use(initReactI18next).init({
	resources,
	lng: !!localStorage.getItem('language')
		? localStorage.getItem('language')
		: 'it',
	interpolation: {
		escapeValue: false,
	},
});
export default i18next;
