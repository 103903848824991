import { useTranslation } from 'react-i18next';
import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';

const Description = (props) => {
	const { i18n } = useTranslation();

	return props.data ? (
		<div className="description layout">
			{props.data.map((item, index) => (
				<div className={index % 2 === 0 ? 'left' : 'right'} key={item.id}>
					<div className="header">
						{!!item.link ? (
							<a href={item.link} target="_blank" rel="noreferrer">
								<img
									className="subject-icon shadow"
									src={item.img}
									alt={item.Description}
								/>
							</a>
						) : (
							<img
								className="subject-icon shadow"
								src={item.img}
								alt={item.Description}
							/>
						)}
						<div>
							{!!item.titleit && !!item.titleen ? (
								<h4>{i18n.language === 'en' ? item.titleen : item.titleit}</h4>
							) : null}
							{!!item.imgCaption ? (
								<img
									className="star-icon"
									src={item.imgCaption}
									alt="5 stelle"
								/>
							) : !!item.caption ? (
								<h5>
									{item.caption}
									{props.hours
										? i18n.language === 'en'
											? ' hours'
											: ' ore'
										: null}
								</h5>
							) : null}
						</div>
					</div>
					{i18n.language === 'en'
						? item.texten.map((text, index) => <p key={index}>{text}</p>)
						: item.textit.map((text, index) => <p key={index}>{text}</p>)}
				</div>
			))}
		</div>
	) : (
		<></>
	);
};
export default Description;
