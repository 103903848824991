import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';
import AATCE from '../img/AATCE.svg';
import Mail from '../img/Mail.svg';
import Instagram from '../img/Instagram.svg';
import Linkedln from '../img/Linkedln.svg';
import Phone from '../img/Phone.svg';

const Footer = (props) => {
	return (
		<footer className="footer">
			<div className="layout">
				<div className="column left">
					<a className="title" href="https://www.iftsdeamicis.com/">
						Corso ifts app developer
					</a>
					<a
						href="https://deamicismilano.com/"
						target="_blank"
						rel="noreferrer"
					>
						Istituti E. De Amicis – Milano
					</a>
					<a className="aacte" href="/AATCE">
						<img src={AATCE} alt="Apple Authorised Training Centre Education" />
					</a>
				</div>
				<div className="column right">
					<div className="social-icon">
						<a href="mailto:ifts@ideamicis.it">
							<img src={Mail} alt="Mail" />
						</a>
						<a
							href="https://www.instagram.com/ifts_deamicis/"
							target="_blank"
							rel="noreferrer"
						>
							<img src={Instagram} alt="Instagram" />
						</a>
						<a
							href="https://it.linkedin.com/school/ifts-deamicis/"
							target="_blank"
							rel="noreferrer"
						>
							<img src={Linkedln} alt="Linkedln" />
						</a>
						<a href="tel:0255195153">
							<img src={Phone} alt="Phone" />
						</a>
					</div>
					<a href="/AATCE">Apple Authorized Training Center</a>
					<a
						href="https://www.regione.lombardia.it/wps/portal/istituzionale/HP/formazioneprofessionale/ifts-cosa-sono"
						target="_blank"
						rel="noreferrer"
					>
						IFTS Regione Lombardia
					</a>
					<a href="/privacypolicy" target="_blank">
						Privacy Policy
					</a>
				</div>
			</div>
			<a
				className="sign"
				href="https://www.scargroup.it"
				target="_blank"
				rel="noreferrer"
			>
				Made with ❤️ by Scar Group
			</a>
		</footer>
	);
};
export default Footer;
