import { useState, useEffect } from 'react';
import './css/utilities.css';
import './css/style.css';
import './css/tablet.css';
import './css/mobile.css';
import './css/smallMobile.css';
import './css/shortMobile.css';
const SecondaryHero = (props) => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<div className="secondary-hero" style={{ height: windowHeight }}>
			<div className="header">
				<img
					className="illustration"
					alt={props.imgDescription}
					src={props.image}
				/>
				<div>
					<h1>{props.title}</h1>
					{!!props.text1 ? <p>{props.text1}</p> : null}
					{!!props.text2 ? (
						!!props.link ? (
							<p>
								{props.text2}{' '}
								<span>
									<a
										className="inlineLink"
										href={props.link}
										target="_blank"
										rel="noreferrer"
									>
										{props.linkText}
									</a>{' '}
								</span>
							</p>
						) : (
							<p>{props.text2}</p>
						)
					) : null}

					{!!props.text3 ? <p>{props.text3}</p> : null}
				</div>
			</div>
			{props.children}
		</div>
	);
};
export default SecondaryHero;
